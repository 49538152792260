/* Control Panel View CSS */
#controlPanel {
    position: fixed;
    width: max-content;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    border-radius: 5px;
}

#controlPanelDiv {
    padding: 0 18px;
}

.controlCenter {
    padding: 10px 0;
    // border-bottom: 1px solid #d9d9d9;
}

#controlBtnSave {
    width: max-content;
    position: fixed;
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 44px;
}

#controlIcon {
    position: fixed;
    width: max-content;
    text-align: center;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#controlIcon:hover {
    cursor: pointer;
}