/* Editor Page CSS */
.editorPageDiv {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.editorPage,
.editorPageMD {
    display: flex;
    width: 200%;
    height: 100%;
    box-sizing: border-box;
}

.editorPageMD {
    width: 300%;
}

.editorPageOne {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.7s;
}

.editorPageOneDiv,
.editorPageOneDivMD {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.editorPageOneDiv {
    justify-content: flex-start;
}

.editorPageOneDivMD {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.editorPageTwo {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.editorPageOneAdd,
.editorPageOneOuter,
.editorPageOneOuterMD,
.editorPageOneOuterNV,
.editorPageOneOuterDrag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.editorPageOneAdd {
    width: 100%;
    height: 20%;
}

.editorPageOneOuter {
    width: 100%;
    height: inherit;
}

.editorPageOneOuterMD {
    width: 50%;
    height: 20%;
}

.editorPageOneOuterNV {
    width: 100%;
    height: 20%;
}

.editorPageOneOuterDrag {
    width: 100%;
    height: inherit;
}

.editorPageOneInner,
.editorPageOneDivDisable {
    width: 80%;
    height: 80%;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    transition: all 0.5s;

    img {
        width: 20%;
        transition: all 0.5s;
    }
}

.editorPageOneInner {
    border: 1px solid;
}

.editorPageOneInner:hover {
    width: 99%;
    height: 100%;
    cursor: pointer;
    font-size: 13px;
    
    img {
        width: 40%;
    }
}

.editorPageOneDivDisable:hover {
    cursor: not-allowed;
}