@import './editor.module.scss';

/* Common CSS */
.introLabel {
    font-weight: bold;
    padding-left: 6px;
}

.introEditorIcons {
    font-size: 1.2rem;
}

.introEditorIconColumn {
    width: 100%;
    height: 81px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Avatar Setting */
.introAvatarRound,
.introAvatarShadow {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.introAvatarRound {
    justify-content: space-around;
}

.introAvatarShadow {
    flex-direction: column;
    padding-bottom: 10px;
}

/* Order Setting */
.introOrderHeader {
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.introOrderTitle {
    position: relative;
    z-index: 1;
    padding: 6px;
    border-radius: 4px;
}

.introOrderTitle:hover {
    cursor: pointer;
}

.introOrderRow {
    position: relative;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.introOrderRow:hover {
    cursor: pointer;
    box-shadow: 5px 5px #888888;
}

.introOrderColOne,
.introOrderColTwo {
    border-radius: 5px;
    padding: 5px;
}

.introOrderColTwo {
    text-align: center;
}

.introOrderSwitchDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.introOrderText {
    padding-left: 10px;
}

/* Extra Setting */
.introExtraHeader,
.introFieldHeader,
.introExtraColor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.introExtraHeader,
.introFieldHeader {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 24px;
}

.introExtraRow,
.introFieldRow {
    padding: 12px 0;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
}

.introColumn,
.introColEditor,
.introColCenter,
.introColBetween,
.introColTextArea {
    display: flex;
    align-content: center;
    align-items: center;
    font-weight: bold;
}

.introColumn {
    padding-left: 20px;
    flex-direction: column;
    align-items: flex-start;
}

.introColCenter {
    justify-content: center;
}

.introColBetween {
    justify-content: space-between;
}

.introColTextArea {
    padding-bottom: 24px;
}

.introSocialMediaEditor {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}