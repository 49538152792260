/* Div CSS */
.editorDivV,
.editorDivP {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.editorDivV {
    flex-direction: column;
}

.editorDivP {
    flex-direction: row;
    justify-content: space-evenly;
}