.baseColContainer,
.baseRowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.baseColContainer {
  flex-direction: column;
}

.baseRowContainer {
  flex-direction: row;
}

#templateDiv {
  background-color: floralwhite;
  overflow: hidden;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

.baseEditDiv {
  top: 10px;
  right: 0px;
  position: fixed;

  .baseEdit {
    color: #fff;
    width: 90px;
    padding: 8px;
    text-align: center;
    word-wrap: break-word;
    border: 1px solid white;
    border-radius: 10px;
  }

  .baseEdit:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.baseSVG {
  width: 100%;

  .svgStyle {
    width: 100%;
    height: 100%;
  }
}
