/* Picker Components CSS */

/* Icon Component CSS */
.pickerIconColumn,
.pickerIconDivOne,
.pickerIconDivTwo {
    display: flex;
    justify-content: center;
}

.pickerIconDivOne {
    background-color: #fff;
    margin: 5px;
}

.pickerIconDivTwo {
    background-color: #000;
    margin: 5px;
}

.pickerIcon {
    display: block;
    margin: auto;
    width: 90px;
}

.pickerIconTT {
    font-size: 90px;
}

/* Picker Images */
.pickerImageDiv {
    padding: 6px;
}

.pickerImage {
    position: relative;
    display: block;
    left: 50%;
    width: 100%;
    height: 100px;
    transform: translateX(-50%);
    object-fit: cover;
}

.pickerURL {
    padding: 0 12px;
    width: 100%;
}

/* Upload Component CSS */
.pickerUploadDiv {
    display: flex;
    justify-content: center;
}

.pickerProgress {
    padding: 5px 5% 0;
}

.pickerText {
    text-align: center;
    padding: 3px 12px;
}

.pickerWarning {
    width: 100%;
    padding: 6px;
    text-align: center;
}

/* Color Picker Component CSS */
.pickerColorDiv,
.pickerGradientDiv {
    display: flex;
    align-items: center;
}

.pickerColorDiv {
    flex-direction: row;
}

/* Gradient Picker Component CSS */
.pickerGradientDiv {
    flex-direction: column;
}

$gradient-cs: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAARCAQAAABzuJQIAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAB7SURBVCjP3c7BDQMhDETRP4gyUlb6SEphe9w+JgdArIMPm2uGizV6MpbpERgBzGb0tuZcLvTbDSoHrMUMQmFrY/IaaOeKB7yE+1yTz43irZNXbXcCFosuXqFt9g0cyZICT7LkrU+b62vGeVv4IX+Phc+tfABZK3xj5cgHC29ECUeufxkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDUtMzFUMTg6NDE6MzIrMDI6MDD31tLGAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA1LTMxVDE4OjQxOjMyKzAyOjAwhotqegAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAASUVORK5CYII=';

.pickerGradientBG {
    height: 17px;
    position: absolute;
    width: 11px;
    background: url($gradient-cs) right center;
    cursor: pointer;

    div {
        height: 7px;
        left: 2px;
        width: 7px;
        position: absolute;
        top: 8px;
    }
}

.pickerImageDiv,
.pickerImage,
.pickerIcon,
.pickerIconTT:hover {
    cursor: pointer;
}