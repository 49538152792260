@import './variables.scss';
@import './editor/editor.div.module.scss';
@import './editor/editor.history.module.scss';
@import './editor/editor.navigation.module.scss';
@import './editor/editor.page.module.scss';
@import './editor/editor.part.module.scss';
@import './editor/editor.picker.module.scss';
@import './editor/editor.preview.module.scss';


.editorRow,
.editorRowSync,
.editorRowSelect {
    padding: 12px 0;
    justify-content: center;
    align-items: center
}

.editorRow,
.editorRowSync {
    border-bottom: 1px solid #f0f0f0;
}

.editorRowHistory {
    bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
}

.editorRowSync:hover {
    cursor: pointer;
}

/* Frame Setting CSS */
.editorFrame {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editorFrameDiv {
    display: flex;
    justify-content: center;
}

/* Module Setting CSS */
.editorModule {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* Overflow Hidden CSS */
.editorHiddenText {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
}