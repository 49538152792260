@import './variables.scss';

.naviBar {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    transition: all .5s ease;
    z-index: 1;

    .navItems:hover {
        cursor: pointer;
    }
}

.menuIcon {
    position: fixed;
    top: 10px;
    left: 10px;
    color: 'white';
    font-size: 3rem;
    z-index: 1;
}

#menuBar {
    position: fixed;
}