/* Editor Parts CSS */

/* Common */
.partDiv {
    text-align: center;
    padding: 0 12px;
}

.partCenter {
    display: flex;
    justify-content: center;
}

.partAround {
    display: flex;
    justify-content: space-around;
}

/* EditorLabel */
.partLabel {
    font-weight: bold;
    padding-left: 6px;
}

/* EditorDropDown */
.partDropDownLabel {
    width: 100%;
}

.partDropDownButton {
    width: 150px;
}

/* EditorImage */
.partPreview {
    display: flex;
    justify-content: center;
}

/* EditorTextArea */
.partTextArea {
    padding: 0 12px 12px;
}

/* EditorHuePicker 
   EditorParallel
   EditorVertical */
.partDivV,
.partDivP {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.partDivV {
    flex-direction: column;
}

.partDivP {
    flex-direction: row;
    justify-content: space-evenly;
}

/* EditorFontPicker */
.partFontDiv {
    width: 100%;
    max-height: 500px;
    overflow: scroll;
}

.partFont:hover {
    color: #FFFFFF;
    cursor: pointer;
}