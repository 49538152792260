@import './variables.scss';

.registerBackground {
    background: black; //url($landing-title-image);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.registerFrame {
    position: absolute;
    border-radius: 15px;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0, 0, 0, 0);
    background: rgba(255, 255, 255, 0.8);
    width: 20%;
    z-index: 1;
}

.registerTitle {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.registerMessageDiv {
    color: #00C851;
    padding: 0 10px;
    text-align: center;
}

.registerMessage {
    margin-bottom: 1.5rem;
}

.registerCenter {
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}

.registerCreate {
    font-weight: 500;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
}

.registerCreate:hover {
    color: rgba(24, 24, 24, 0.85);
    text-decoration: underline;
}

.registerActiveDiv {
    color: rgba(24, 24, 24, 0.85);
    padding: 0 10px;
    text-align: center;
}

.registerDiv {
    width: 80%
}

.registerButton {
    width: 100%
}

// Signin Module
.signinThirdRow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.signinTime {
    font-weight: 500;
}

.signinForget {
    font-weight: 500;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
}

.signinError {
    color: #ff4d4f;
    padding: 0 10px;
    text-align: center;
}

.signinMessage {
    color: #00C851;
    padding: 0 10px;
    text-align: center;
}

.signinCenter {
    text-align: center;
}

.signinCreate {
    font-weight: 500;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
}

.signinCreate:hover {
    color: rgba(24, 24, 24, 0.85);
    text-decoration: underline;
}

.signinForget:hover,
.signinCreate:hover {
    cursor: pointer;
}


// SignUp Module
.signupForm {
    width: 80%;
}

.signupError {
    color: #ff4d4f;
    padding: 0 10px;
    text-align: center;
}

.signupForget:hover {
    cursor: pointer;
}

// Password Module

.passwordContainer {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
}

.passwordDiv {
    display: flex;
    justify-content: space-between;
}

.passwordStrengthDiv {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

.passwordStrength {
    height: 5px;
    flex-grow: 1;
    margin: 0 2px;
    text-align: center;
    font-size: 11px;
}

.signoutSection {
    text-align: center;
}

.registerResetDiv {
    color: rgba(24, 24, 24, 0.85);
    padding: 0 10px;
}

@media screen and (max-width: 1600px) {
    .registerFrame {
        width: 30% !important;
    }
}

@media screen and (max-width: 1200px) {
    .registerFrame {
        width: 40% !important;
    }
}

@media screen and (max-width: 992px) {
    .registerFrame {
        width: 40% !important;
    }
}

@media screen and (max-width: 768px) {
    .registerFrame {
        width: 60% !important;
    }
}

@media screen and (max-width: 576px) {
    .registerFrame {
        width: 80% !important;
        height: auto !important;
    }

    .registerTitle {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 480px) {
    .registerFrame {
        width: 80% !important;
    }
}