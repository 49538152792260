@import './variables.scss';

.background {
    width: 100%;
}

.backgroundNotExistDiv {
    background: black; //url($landing-title-image);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.backgroundNotExistFrame {
    position: absolute;
    border-radius: 15px;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0, 0, 0, 0);
    background: rgba(255, 255, 255, 0.5);
    width: 20%;
    z-index: 1;
}

.backgroundResetDiv {
    color: rgba(24, 24, 24, 0.85);
    padding: 0 10px;
    text-align: center;
}

.backgroundSVG {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1600px) {
    .backgroundNotExistFrame {
        width: 30% !important;
    }
}

@media screen and (max-width: 1200px) {
    .backgroundNotExistFrame {
        width: 40% !important;
    }
}

@media screen and (max-width: 992px) {
    .backgroundNotExistFrame {
        width: 40% !important;
    }
}

@media screen and (max-width: 768px) {
    .backgroundNotExistFrame {
        width: 60% !important;
    }
}

@media screen and (max-width: 576px) {
    .backgroundNotExistFrame {
        width: 80% !important;
        height: auto !important;
    }

    .registerTitle {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 480px) {
    .backgroundNotExistFrame {
        width: 80% !important;
    }
}