@import './variables.scss';
@import './frame/frame.intro.module.scss';

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:-webkit-any-link {
  color: inherit !important;
  cursor: pointer;
}

.frameDiv:hover {
  cursor: pointer;
}

.frame {
  display: flex;
  position: absolute;
  padding: 20px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: translateX(-50%);
}

.frameHintDiv {
  top: 10px;
  right: 10px;
  position: fixed;

  .frameMoveHint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    width: 200px;
    height: 45px;
    padding: 10px;
    word-wrap: break-word;
    border-radius: 5px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }
}

.frameSaveDiv {
  right: 10px;
  position: absolute;

  .frameSaveHint {
    width: 108px;
    height: 80px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.frameSVG {
  width: 100%;
  position: absolute;
  z-index: -1;

  .svgStyle {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .frame {
    width: 80% !important;
  }
}

@media screen and (max-width: 576px) {
  .frame {
    width: 90% !important;
    height: auto !important;
    flex-direction: column;
    padding: 20px 20px;
  }
}

@media screen and (max-width: 480px) {
  .frame {
    width: 90% !important;
  }
}
