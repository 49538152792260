/* Preview CSS */
.editorPreviewIconA,
.editorPreviewIconB,
.editorPreviewImage {
    display: flex;
    justify-content: center;
}

.editorPreviewIconA,
.editorPreviewIconB {
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px;
}

.editorPreviewIconA {
    background: #000000;
}

.editorPreviewIconB {
    background: #ffffff;
}

.editorPreviewImage {
    width: 70%;
    height: 150px;
}