/* History CSS */
.historyDiv {
    padding: 12px 6px;
}

.historyImage,
.historyIcon {
    display: flex;
    width: 60px;
    height: 80px;
    object-fit: cover;
    align-items: center;
}

.historyImage {
    background: #000000;
}

.historyBG {
    width: 60px;
    height: 45px;
    object-fit: cover;
}

.historyDiv:hover {
    cursor: pointer;
}

@media screen and (max-width: 576px) {

    .historyImage,
    .historyIcon {
        width: 45px;
    }

    .historyBG {
        width: 45px;
    }
}

@media screen and (max-width: 420px) {

    .historyImage,
    .historyIcon {
        width: 32px;
    }

    .historyBG {
        width: 32px;
    }
}