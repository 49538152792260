/* Navigation Editor CSS */
.editorNavFrame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 300px;
}

.editorNavPreview {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.editorMenuTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
}

.editorMenuItems {
    padding: 24px;
}

.editorSyncCenter {
    text-align: center;
}