// @import './frame.module.scss';

// Avatar
.introAvatarDiv {
    padding-right: 20px;
    justify-content: center;
    align-items: center;
}

.introAvatar {
    object-position: center;
}

// Biography
.introBiography {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 100%;

    // .introBioIconDiv {
    //     word-break: break-all;
    //     align-items: center;

    //     .introBioIcons {
    //         font-size: 1.2rem;
    //         padding-right: 10px;
    //     }
    // }

    // .bioAddress {
    //     div {
    //         margin-bottom: 3px;
    //     }
    // }

    // .introSocialMedia {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    // }

    // .introSocialMedia:hover {
    //     cursor: pointer;
    // }
}

// .introBioIconDiv {
//     word-break: break-all;
//     align-items: center;
// }

.introBioIcons {
    padding-right: 10px;
}

.introRowMultiple {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.introIcons:hover {
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .introBiography {
        padding: 0 20px;
    }
}

@media screen and (max-width: 576px) {
    .introBiography {
        width: 100%;
        padding: 20px 40px;
    }

    .introAvatarDiv {
        width: 100%;
        padding: 0 0 20px 0;
    }
}

@media screen and (max-width: 480px) {
    .introBiography {
        width: 100%;
        padding: 20px 20px;
    }
}